import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React, { useState } from "react";
import logoImg from "../images/logo.png";
import { Fade } from "react-awesome-reveal";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  return (
    <nav
      className="container mx-auto px-8 pt-4 relative z-10"
      aria-label="Main Navigation"
    >
      <div className="flex justify-between items-center">
        <AnchorLink
          className="text-black font-poppins flex items-center justify-between gap-5"
          to="/"
        >
          <img className="w-14 h-14" src={logoImg} alt="Header" />
          <p className="text-black font-poppins font-semibold ">
            Ritz Portfolio
          </p>
        </AnchorLink>

        {/* Hamburger button visible on mobile */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                // Close icon (X)
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                // Hamburger icon
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        <ul className="hidden md:flex  space-x-8 list-none m-0">
          <li>
            <AnchorLink
              to={"#experience"}
              stripHash
              title={"Go to experience section"}
              className="text-black hover:text-pinkish-300 px-3 py-2 text-lg font-medium font-poppins"
            >
              Experience
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to={"#contact"}
              stripHash
              title={"Go to experience section"}
              className="text-black hover:text-pinkish-300  px-3 py-2 text-lg font-medium font-poppins"
            >
              Contact
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to={"#clients"}
              stripHash
              title={"Go to experience section"}
              className="text-black hover:text-pinkish-300  px-3 py-2 text-lg font-medium font-poppins"
            >
              Clients
            </AnchorLink>
          </li>
          <li>
            <Link
              to={"#blogs"}
              className="text-black hover:text-pinkish-300  px-3 py-2 text-lg font-medium font-poppins"
            >
              Blogs
            </Link>
          </li>
        </ul>
      </div>
      {/* Mobile drop-down menu */}
      {isOpen && (
        <Fade>
          <div className="md:hidden mt-4 bg-white bg-opacity-70 shadow-md rounded-lg absolute top-15 w-5/6">
            <ul className="flex flex-col space-y-2 p-4">
              <li>
                <AnchorLink
                  to="#experience"
                  stripHash
                  title="Go to experience section"
                  className="block text-black hover:text-pinkish-300 px-3 py-2 text-lg font-medium font-poppins"
                >
                  Experience
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  to="#contact"
                  stripHash
                  title="Go to contact section"
                  className="block text-black hover:text-pinkish-300 px-3 py-2 text-lg font-medium font-poppins"
                >
                  Contact
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  to="#clients"
                  stripHash
                  title="Go to clients section"
                  className="block text-black hover:text-pinkish-300 px-3 py-2 text-lg font-medium font-poppins"
                >
                  Clients
                </AnchorLink>
              </li>
              <li>
                <Link
                  to="#"
                  className="block text-black hover:text-pinkish-300 px-3 py-2 text-lg font-medium font-poppins"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </Fade>
      )}
    </nav>
  );
}
