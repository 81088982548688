import React from "react";
import { socialMedia } from "../data/data.js";
import styled from "styled-components";
const HoverBox = styled.div<{ bgColor: string }>`
  width: 50px;
  height: 50px;
  transition: fill 0.3s;
  &:hover {
    fill: ${(props) => props.bgColor || "gray"};
  }
`;
export default function Footer() {
  return (
    <footer className="relative text-black text-center">
      <div className="w-full h-auto overflow-hidden -mb-[0.141px]">
        <svg
          className="w-full h-auto"
          viewBox="0 0 1440 344"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 258L60 229.333C120 200.667 240 143.333 360 150.5C480 157.667 600 229.333 720 229.333C840 229.333 960 157.667 1080 107.5C1200 57.3333 1320 28.6667 1380 14.3333L1440 0V344H1380C1320 344 1200 344 1080 344C960 344 840 344 720 344C600 344 480 344 360 344C240 344 120 344 60 344H0V258Z"
            fill="#FF85C894"
          />
        </svg>
      </div>
      <div className="bg-pink w-full h-auto flex flex-col space-y-5 justify-center p-7">
        <div className="flex space-x-2 justify-center">
          {socialMedia.map(
            (
              {
                icon,
                color,
                link,
              }: { icon: string; color: string; link: string },
              index: number
            ) => (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                key={index + ""}
                aria-label={index + ""}
              >
                <div>
                  <HoverBox bgColor={color}>
                    {icon && (
                      <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                    )}
                  </HoverBox>
                </div>
              </a>
            )
          )}
        </div>
        <div className="flex space-x-2 justify-center">
          <p>&copy; 2024 All Rights Reserved.</p>
          <p>By Kamyab Rouhifar</p>
        </div>
      </div>
    </footer>
  );
}
