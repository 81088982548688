import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Layout from "./src/components/Layout"; // Assuming Layout component is defined in Layout.tsx
import BlogLayout from "./src/components/BlogLayout";
import "./src/styles/global.css";

const firebaseConfig = {
  apiKey: "AIzaSyCwNgkRMMw27wJTzW2vGl4NuVJUay0G5cA",
  authDomain: "mini-portfolio-e9214.firebaseapp.com",
  projectId: "mini-portfolio-e9214",
  storageBucket: "mini-portfolio-e9214.firebasestorage.app",
  messagingSenderId: "368032895221",
  appId: "1:368032895221:web:326980f64479c88e2fb5bc",
  measurementId: "G-LST2QR8SJZ",
};

const useFirebaseAnalytics = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const app = initializeApp(firebaseConfig);
      getAnalytics(app);
    }
  }, []);
};

export const wrapPageElement = ({ element, props }) => {
  useFirebaseAnalytics();
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Now we know the code is running on the client.
      setIsClient(true);
    }
  }, []);
  const { location } = props;
  return (
    <>
      {isClient ? (
        <>
          {location.pathname.includes("/blog") ? (
            <BlogLayout {...props}>{element}</BlogLayout>
          ) : (
            <Layout {...props}>{element}</Layout>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export const onRouteUpdate = () => {
  window.scrollTo(0, 0);
};
