import React, { ReactNode } from "react";
import Footer from "./Footer";

export default function BlogLayout({
  children,
  props,
}: {
  children: ReactNode;
  props: any;
}) {
  return (
    <div {...props}>
      {children}
      <Footer />
    </div>
  );
}
