import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Layout({
  children,
  props,
}: {
  children: ReactNode;
  props: any;
}) {
  return (
    <div {...props} className="h-screen">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
